<template>
	<div class="columns is-centered">
		<div class="column is-3">
			<form class="form-horizontal" autocomplete="off" >
				<fieldset>
					<!-- Form Name -->
					<legend class="has-text-right">Register</legend>

					<!-- Text input-->
					<div class="field">
						<label class="label" for="textinput-n">Name</label>
						<div class="control">
							<input id="textinput-n" v-model="new_user.name"
								name="textinput-n" type="text" placeholder="placeholder"
								class="input " autocomplete="new-password">
							<p class="help">Your email here</p>
						</div>
					</div>
					<!-- Text input-->
					<div class="field">
						<label class="label" for="textinput-0">Email</label>
						<div class="control">
							<input id="textinput-0" v-model="new_user.email"
								name="textinput-0" type="email" placeholder="placeholder"
								class="input " autocomplete="new-password">
							<p class="help">Your email here</p>
						</div>
					</div>

					<!-- Password input-->
					<div class="field">
						<label class="label" for="passwordinput-0">Password</label>
						<div class="control">
							<input id="passwordinput-0" v-model="new_user.password"
								name="passwordinput-0"
								type="password"
								placeholder="Password" class="input"
								readonly
								onfocus="this.removeAttribute('readonly');">
							<p class="help">Specify your password</p>
						</div>
					</div>

					<!-- Password input-->
					<div class="field">
						<label class="label" for="passwordinput-1">Repeat Password</label>
						<div class="control">
							<input id="passwordinput-1"
								v-model="new_user.password_confirmation" name="passwordinput-1"
								type="password" placeholder="placeholder" class="input" 
								readonly onfocus="this.removeAttribute('readonly');">
							<p class="help">Repeat your password here</p>
						</div>
					</div>
					<div class="field border-top">
						<router-link to="/login" class="button is-small is-pulled-left">
							<i class="fa-solid fa-chevron-left mr-2 has-text-grey"></i>
							Login
						</router-link>
						<button 
							class="button is-small is-pulled-right is-info is-outlined" 
							@click.prevent="register">Register</button>
					</div>
				</fieldset>
			</form></div>
	</div>
</template>
<script charset="utf-8">
import Api from '@/services/Api.js'
export default {
	data(){
		return {
			new_user: {
				name: '',
				firstname: '-',
				lastname: '-',
				email: '',
				password: '',
				password_confirmation: ''
			}	
		}
	}, 
	created(){
		this.$document.title = "Register"
	},
	methods: {
		register(){
			Api().post('user/register', this.new_user)
				.then(r => {
					localStorage.user = JSON.stringify(r.data.user)
					localStorage.token = r.data.token
				})
				.catch(error => {
					var err = error.response.data
					this.$toast.warning(err.message)
					Object.keys(err['errors']).map((e, i) => {
						this.$toast.error((i+1) + '. ' + err.errors[e])
					})
				})
		}
	}
}	
</script>
